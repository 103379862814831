import React, { FunctionComponent } from 'react';
import { VideoFieldsFragment } from '../../../__generated__/graphql-client-types';
import { CaptionOverlay } from '../../common-components/caption-overlay/caption-overlay.component';
import { VideoCard } from '../../common-components/video-card/video-card.component';

export const RelatedVideo: FunctionComponent<VideoFieldsFragment> = (video) => (
	<CaptionOverlay
		caption={
			<div className={'f6'}>
				<div className={'b theme-grey-darker'}>{video.title}</div>
			</div>
		}
		className="ma2">
		<VideoCard video={video} previewWidthPx={442} previewHeightPx={274} />
	</CaptionOverlay>
);
