import { useEffect, useState } from 'react';
import { LARGE_MEDIA_QUERY, MEDIUM_MEDIA_QUERY } from '../../constants/media-queries';
import { generateListener } from '../../helpers/general-helper/general-helper';

export type Breakpoints = {
	isMedium: boolean;
	isLarge: boolean;
	isNotSmall: boolean;
};

/**
 * @deprecated this hook has been deprecated in favor of useClientOnlyBreakpoints.  We should only be using breakpoints on client renders. This hook will be removed in a future release.
 * useBreakpoints - a custom hook to enable use of tachyons breakpoints client side
 *
 * NOTE: because this hook relies on window.matchMedia all breakpoints will first report false, before being updated. It will also report
 * false during SSR rendering. If your content can render during SSR, this hook should not be used to conditionally render that content as it's likely to
 * cause layout shifts after the page is hydrated since the SSR output will not match the client render once this hook resolves the breakpoints.
 * While using this hook in those scenarios can reduce the size of the DOM output, it can be negated by the cost of re-rendering and layout shifting.
 * This hook is better suited for making rendering decisions that will only ever occur on the client, such as whether to show a modal vs. drawer in response
 * to a user interaction.
 * @param resolveNow - if true, the breakpoints will be resolved initially vs. waiting for the hook to mount.  This can be useful
 * if you know your component will only ever render on the client and you don't want incorrect values initially.
 * TODO: https://github.com/orgs/buildcom/projects/3#card-87357818 - we should consider making this the default behavior.
 */

export function useBreakpoints(resolveNow = false): Breakpoints {
	const isMediumInit = resolveNow ? window.matchMedia(MEDIUM_MEDIA_QUERY).matches : false;
	const isLargeInit = resolveNow ? window.matchMedia(LARGE_MEDIA_QUERY).matches : false;
	const [isMedium, setIsMedium] = useState(isMediumInit);
	const [isLarge, setIsLarge] = useState(isLargeInit);

	const mediumListener = generateListener(setIsMedium);
	const largeListener = generateListener(setIsLarge);

	useEffect(() => {
		const mediumMediaQueryList = window.matchMedia(MEDIUM_MEDIA_QUERY);
		const largeMediaQueryList = window.matchMedia(LARGE_MEDIA_QUERY);

		// initial checks
		mediumListener(mediumMediaQueryList);
		largeListener(largeMediaQueryList);

		// listen for changes
		// addListener is deprecated but safari < 14 doesn't support addEventListener
		mediumMediaQueryList.addListener(mediumListener);
		largeMediaQueryList.addListener(largeListener);

		// remove listeners on unmount
		return () => {
			// removeListener is deprecated but safari < 14 doesn't support removeEventListener
			mediumMediaQueryList.removeListener(mediumListener);
			largeMediaQueryList.removeListener(largeListener);
		};
	}, [isMedium, isLarge, mediumListener, largeListener]);

	return { isMedium, isLarge, isNotSmall: isMedium || isLarge };
}
