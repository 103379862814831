import React, { FunctionComponent, PropsWithChildren } from 'react';

export type CaptionOverlayProps = {
	caption: JSX.Element | string;
	className?: string;
};

export const CaptionOverlay: FunctionComponent<PropsWithChildren<CaptionOverlayProps>> = ({ caption, className = '', children }) => {
	return (
		<figure className={`ma0 relative-ns ${className}`}>
			{children}
			<figcaption className="w-100 w-80-ns absolute-ns right-0-ns bottom-0-ns bg-theme-white pa3 lh-copy border-box">
				{caption}
			</figcaption>
		</figure>
	);
};
